.MultiSelect-DropDown-AntDesign {
  width: 100%;
  padding: 3px 0;
  border-radius: var(--multiselectDropdownAntDesignBorderRadius);
}
.MultiSelect-DropDown-AntDesign .ant-select-selection-item-remove {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
  box-shadow: none;
}

.MultiSelect-DropDown-AntDesign .ant-select-selector {
  border: none !important;
}

.MultiSelect-DropDown-AntDesign:hover.ant-select:not(.ant-select-disabled):hover
  .ant-select-selector {
  border-color: transparent;
}

.MultiSelect-DropDown-AntDesign .ant-select-clear {
  background: transparent;
  margin-top: -8px;
}

.tooltip-inner {
  text-align: left;
  max-width: 58vw !important;
}

#info-icon {
  color: var(--infoIconColor);
}
