.assembly-chat-popover {
    width: 450px;
    max-width: 1500px;
    border: none;
}

    .assembly-chat-popover .container-fluid {
        padding-top: 10px;
    }

    .assembly-chat-popover .popover-body {
        padding: 0px;
        box-shadow: -1px -1px 20px 16px rgb(95 95 95 / 30%);
        border-radius: 10px;
    }

    .assembly-chat-popover .chat-content {
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 500px;
        height: 360px;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
    }

    .assembly-chat-popover textarea {
        border: none;
        padding: 7px 0;
        resize: none;
    }

        .assembly-chat-popover textarea:focus {
            border: none;
        }

    .assembly-chat-popover .icon-cursor {
        cursor: pointer;
    }

    .assembly-chat-popover .chat-date {
        padding: 10px 0px 10px 0px;
        text-align: center;
    }

    .assembly-chat-popover .msg-box {
        padding: 8px 10px;
        margin-bottom: 8px;
        display: inline-block;
        text-align: left;
        border-radius: 10px;
    }

    .assembly-chat-popover .svg-r {
        height: 1.2em;
        width: 1.2em;
        color: var(--quantityRemoveButton);
        margin-top: 0;
        float: right;
    }

    .assembly-chat-popover .chat-receiver {
        background-color: rgba(241,241,241,1);
    }

    .assembly-chat-popover .chat-sender {
        background-color: rgba(220,225,249,1);
    }

    .align-middle {
        display: flex;
        align-items: center;
        justify-content: center;
    }

.word-break {
    word-break: break-all;
}
