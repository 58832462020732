.nav-item > a > svg {
  margin: 0 5px 4px 0;
}
.rfq-steps {
    height: auto !important;
}

.nav-link {
  color: var(--navLinkColor) !important;
}

.cm-checkout-stepper {
  padding-left: 40px;
  padding-top: 5px;
}

.cm-checkout-stepper
  .ant-steps-vertical
  > .ant-steps-item
  .ant-steps-item-content {
  min-height: 33px;
}

.cm-checkout-stepper
  > .ant-steps-vertical
  > .ant-steps-item
  .ant-steps-item-title {
  font-size: var(--cmCheckoutStepperFontSize);
}

.cm-checkout-stepper
  > .ant-steps-vertical
  > .ant-steps-item
  .ant-steps-item-title {
  line-height: 24px;
}

.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: var(--checkoutStepsDotColor);
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--antStepsItemFinishBackground);
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: var(--antStepsItemFinishBackground);
}

.cm-active-step {
  color: var(--stepsCompletedColor) !important;
}

.cm-link-disable > svg {
  color: var(--cmLinkDisableColor) !important;
}

.cm-link-disable {
  color: var(--cmLinkDisableColor) !important;
}

.nav-item a:hover {
  color: var(--stepsCompletedColorHover) !important;
}

.icon-color {
  color: var(--faRegCheckCircleColor);
}
