.btn-primary-orange {
  background: var(--btnPrimaryOrangeBackground);
  color: var(--btnPrimaryOrangeColor);
  text-align: center;
  border-color: var(--btnPrimaryOrangeBorderColor) !important;
  padding: 10px 50px 10px 50px;
}

.btn-primary-orange:hover,
.btn-primary-orange:active,
.btn-primary-orange:visited,
.btn-primary-orange:focus {
  background-color: var(--btnPrimaryOrangeBackground) !important;
  border-color: var(--btnPrimaryOrangeActiveBorderColor) !important;
  box-shadow: none !important;
}

.btn-generic-primary {
    background: var(--primaryButtonBackgroundColor);
    color: var(--primaryButtonColor);
    text-align: center;
    border-color: var(--primaryButtonBorderColor) !important;
    padding: 10px 50px 10px 50px;
}

    .btn-generic-primary:hover,
    .btn-generic-primary:active,
    .btn-generic-primary:visited,
    .btn-generic-primary:focus {
        background-color: var(--primaryButtonBackgroundColorHover) !important;
        color: var(--primaryButtonColorHover) !important;
        border-color: var(--primaryButtonBorderColorHover) !important;
        box-shadow: none !important;
        cursor: pointer;
    }

.btn-secondary {
  background: var(--btnSecondaryBackground);
  color: var(--btnSecondaryColor);
  text-align: center;
  border-color: var(--btnSecondaryBorderColor) !important;
  text-transform: uppercase;
  padding: 10px 50px 10px 50px;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:focus {
  background-color: var(--btnSecondaryBackgroundHover) !important;
  color: var(--btnSecondaryColorHover);
  border-color: var(--btnSecondaryBorderColorHover) !important;
  box-shadow: none !important;
  cursor: pointer;
}

.btn-remove {
  color: var(--btnRemoveColor);
  font-size: var(--btnRemovefontSize);
  cursor: pointer;
}

.btn-add {
  color: var(--btnAddColor);
  font-size: var(--btnAddfontSize);
  cursor: pointer;
}

.btn-filter-toggle {
    /* background: #3a3a3a; */
    background: none;
    text-align: center;
    flex-wrap: wrap;
    box-shadow: none !important;
    color: var(--tertiaryButtonColor);
    border-color: var(--tertiaryButtonBorderColor) !important;
    background: var(--tertiaryButtonBackgroundColor,initial);
}
    .isActive,
    .btn-filter-toggle:hover,
    .btn-filter-toggle:focus,
    .btn-filter-toggle:active,
    .btn-filter-toggle:visited {
        background: var(--tertiaryButtonBackgroundColorHover) !important;
        color: var(--tertiaryButtonColorHover) !important;
        box-shadow: none !important;
        border-color: var(--tertiaryButtonBackgroundColorHover) !important;
    }

.btn-report-secondary {
  background: var(--downloadQuoteButtonBackground) !important;
  color: var(--downloadQuoteButtonColor);
  border: 2px solid var(--downloadQuoteButtonBorderColor) !important;
  padding: 10px 50px 10px 50px;
}

.btn-report-secondary:hover,
.btn-report-secondary:focus,
.btn-report-secondary:active {
  background: var(--downloadQuoteButtonBackgroundHoverColor) !important;
  color: var(--downloadQuoteButtonHoverColor) !important;
  cursor: pointer;
  border: 2px solid var(--downloadQuoteButtonBorderHoverColor) !important;
}

.disable-input {
  pointer-events: none;
  opacity: 0.7;
}

.cm-send-icon {
    background: var(--primaryButtonBackgroundColor);
    min-height: 34px;
    min-width: 37px;
    cursor: pointer;
    border-radius: 100%;
    color: var(--primaryButtonColor);
    padding: 2px 2px 2px 5px;
}
