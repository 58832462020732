#root {
    height: 100vh;
}

.cm-sidenav {
    background: var(--configheaderBackground);
    color: var(--configlinktextColor);
}

.cm-flex-1 {
    flex: 1;
    min-width: 0;
}

.configurations-layout {
    height: 100% !important;
}

.cm-sidenav .cm-sidenav-list {
    width: 100%;
}

.cm-overflow-x-hidden {
    overflow-x: hidden !important;
}

.cm-sidenav h3 {
    color: var(--confignavtitlecolor)
}

.nav-item {
    cursor: pointer;
}

.cm-sidenav-list .nav-item > a,
.cm-sidenav-list .nav-item > a:hover {
    text-decoration: none !important;
}

.cm-sidenav-list .nav-item.active,
.cm-sidenav-list .nav-item:hover {
    background-color: var(--configselectedlinkColor);
}

.cm-sidenav-list .nav-item > a {
    color: var(--configlinktextColor) !important;
    display: block;
}

.cm-sidenav-list .nav-item.active > a, .cm-sidenav-list .nav-item:hover > a {
    color: var(--configselectedlinktextColor) !important;
    display: block;
}

.cm-editable-cell {
    font-size: 14px;
    border-color: transparent;
    background: transparent !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cm-disable-single-click {
    border-color: transparent !important;
}

.cm-editable-cell:hover,
.cm-editable-cell:focus,
.cm-editable-cell:focus-within {
    background: none;
}

.cm-editable-cell.input-active {
    box-shadow: rgb(9 109 217 / 20%) 0px 0 0 2px;
    border-color: #1890ff;
}

.cm-config-primary.disabled,
.cm-config-primary:disabled {
    color: var(--primaryButtonColor);
    background-color: var(--primaryButtonBackgroundColor);
    cursor: not-allowed;
}

.cm-config-secondary.disabled,
.cm-config-secondary:disabled {
    cursor: not-allowed;
}

.cm-config-secondary {
    color: var(--secondaryButtonColor) !important;
    background-color: var(--secondaryButtonBackgroundColor) !important;
    border-color: var(--secondaryButtonBorderColor) !important;
}

    .cm-config-secondary:hover {
        color: var(--secondaryButtonColorHover) !important;
        background-color: var(--secondaryButtonBackgroundColorHover);
    }

.btn-secondary:hover {
    color: var(--secondaryButtonColorHover) !important;
}

.cm-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.cm-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.cm-scrollbar::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

    .cm-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #919191;
    }

.cm-configurations-table .ant-table-body {
    max-height: calc(100vh - 381px) !important;
}

    .cm-configurations-table .ant-table-body::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    .cm-configurations-table .ant-table-body::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .cm-configurations-table .ant-table-body::-webkit-scrollbar-thumb {
        background: #c1c1c1;
    }

        .cm-configurations-table .ant-table-body::-webkit-scrollbar-thumb:hover {
            background: #919191;
        }

.cm-configuration-title-mr {
    margin-top: var(--configurationsMenuTitleTopMargin);
}

.em-editable {    
    border-color: #1890ff;
}

    .em-editable .form-control {
        box-shadow: rgb(9 109 217 / 20%) 0px 0 0 2px;
        border-color: #1890ff;
        scroll-behavior: initial;
        overflow: scroll;
        max-height: 90px;
        height: 85px;
    }

.btn-edit {
    color: var(--primaryColor);
    cursor: pointer !important;
    border: none;
}

.cm-modal-content .modal-dialog {
    max-width: 800px !important;
}

.jodit .jodit-workplace {
    max-height: 150px !important;
}

.cm-select-sectionName .form-control {
    height: auto;
}

#configuration-wrapper1 > div {
    padding-top:10px;
}