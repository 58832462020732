a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem var(--boxShadowColor);
}

nav a:hover {
  text-decoration: underline !important;
}

.cm-header-row {
  background: var(--headerBackground);
  padding: var(--cmHeaderRowPadding);
  box-shadow: var(--cmHeaderRowBoxShadow);
}

.div-logo {
    padding-left: var(--logoParentPaddingLeftSize);
    height: var(--logoParentHeightSize);
}

.profile {
    margin-top: var(--headerIconMarginTop);
}

.profile .float-right {
  display: flex;
}

    .profile svg {
        color: var(--profileIconColor) !important;
        height: 2.5em;
        width: 2.5em;
        cursor: pointer !important;
    }

/* change bootStrap dropdown-menu color */
.dropdown a {
    background: var(--dropdownMenuLinkBackgroundColor) !important;
    color: var(--dropdownMenuLinkColor) !important;
}

    .dropdown a:hover {
        background-color: var(--dropdownMenuLinkBackgroundColorHover) !important;
        color: var(--dropdownMenuLinkColorHover) !important;
    }

    .dropdown a:active {
        background-color: var(--dropdownMenuLinkBackgroundColorHover) !important;
        color: var(--dropdownMenuLinkColorHover) !important;
    }

    .dropdown a:focus {
        background-color: var(--dropdownMenuLinkBackgroundColorHover) !important;
        color: var(--dropdownMenuLinkColorHover) !important;
    }

.dropdown-item.active {
    background-color: var(--dropdownMenuLinkBackgroundColor) !important;
    color: var(--dropdownMenuLinkColor) !important;
}

.header {
  max-height: 121px;
}

.user-name {
    font-size: 1.1rem;
    display: flex;
    align-items: end;
    justify-content: right;
    color: var(--profileIconColor) !important;
    padding-right:20px;
}