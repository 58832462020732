.icon-cursor {
    cursor: pointer;
}

.svg-r {
    height: 2em;
    width: 2em;
    color: var(--uploaderWithModalSvgColor);
    margin-top: -15px;
    float: right;
}

.files-view {
    margin-top: 20px;
    margin-right: 20px;
    max-height: 250px;
    overflow-y: auto !important;
}