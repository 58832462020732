.loading-color {
    color: var(--primaryColor);
}

.loading-image {
    background: var(--loaderLogoImage);
    width: var(--loaderLogoWidth);
    height: var(--loaderLogoHeight);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: var(--loaderLogoBackground);
    margin: auto;
    background-position: center;
}
