.document-content {
    width: 580px;
    max-width: 1500px;
    border: none;
}

    .document-content .container-fluid {
        padding-top: 25px;
    }

    .document-content .file-uploder {
        height: 230px;
    }

        .document-content .file-uploder .margin-35 {
            margin-top: 20px !important;
            margin-bottom: 20px !important;
        }

    .document-content .popover-body {
        padding: 0px;
        box-shadow: -1px -1px 20px 16px rgb(95 95 95 / 30%);
    }

.cmTopNavMenu .dropdown-menu.show {
    top: 100% !important;
}

.TopNavMenuColor {
    color: var(--dropdownMenuLinkColor) !important;
}

.chat-count-bubble {
    height: 8px;
    width: 8px;
    display: inline-block;
    padding: 0.4em 0.4em;
    border-radius: 1rem;
    color: var(--badgeLightColor);
    background-color: var(--chatBubbleColor);
    position: absolute;
    top: 2px;
    right: 73px;
}
